<template>
  <div class="grey darken-4">
    <BannerHeader
      title="Preconstruction"
      subtitle="Our team utilizes the latest technologies in project estimations."
      uuid="688c0cf2-380f-4de9-b13d-dc5fb5e058b8"
    />
    <ServicesContent
      :bullets="bullets"
      class="mt-12"
      :images="images"
    >
      <h2 class="text-h5 grey--text text--lighten-3 text-uppercase font-weight-medium">
        C&C Industrial understands that there is a lot that goes into making a project successful.
      </h2>
      <v-divider class="primary my-4" />
      <div class="grey--text text--lighten-2">
        <p>That is why we work with our clients to help ensure we meet critical path goals by setting up an effective project schedule. We do this by looking at the means and methods that will keep cost down yet push the schedule.
        </p>
        <p>This is accomplished through preparation and having a team approach coordinating our Preconstruction, Project Management, and Superintendents as one unit to exceed our client’s expectations.
        </p>
        <p>Our preconstruction team utilizes the latest technology in project estimations. By using <em class="white--text">Mc2</em> &amp; <em class="white--text">On-Screen Takeoff</em> we ensure accuracy in all projects. Our project managers use <em class="white--text">Microsoft Project Scheduling</em> to help you in meeting the all-important job deadlines.
        </p>
        <p>Prior to mobilizing construction equipment & labor, projects begin in-house with <em class="white--text">AutoCAD</em> layout followed by the actual job site layout utilizing fully robotic <em class="white--text">Total Station</em> technology to ensure maximum accuracy, efficiency and cost savings.
        </p>
        <p>Workplace safety & health is incorporated into the planning process from start to finish.</p>
      </div>
      <template v-slot:action>
        <v-divider class="primary mb-2" />
        <h3 class="text-subtitle-1 grey--text text--lighten-2 text-uppercase">
          Submit a Preconstruction Request For Proposal
        </h3>
        <div class="text-center">
          <v-btn
            class="mt-4"
            color="info"
            @click="showPreconDialogForm = true"
          >
            Request For Proposal
            <v-icon
              right
              small
            >fa-paper-plane</v-icon>
          </v-btn>
        </div>
      </template>
    </ServicesContent>
    <PreconstructionFormDialog
      v-if="showPreconDialogForm"
      @close-dialog="showPreconDialogForm = false"
    />
  </div>
</template>

<script>
import BannerHeader from '@/core/components/BannerHeader'
import ServicesContent from '../components/ServicesContent'

export default {
  metaInfo: {
    title: 'Preconstruction',
    description: 'C&C Industrial understands there is a lot that goes into making a project successful. Our preconstruction team utilizes the latest technology in project estimations. Our project managers help you in meeting the all-important job deadlines.'
  },
  components: {
    BannerHeader,
    ServicesContent,
    PreconstructionFormDialog: () => import(/* webpackChunkName: "preconstruction-form-dialog" */ '@/modules/contact/components/PreconstructionFormDialog')

  },
  data () {
    return {
      bullets: [
        { title: 'Value Engineering Services' },
        { title: 'Critical Path Goals/Project Schedule' },
        { title: 'Top-Down and Bottom-Up Estimates' },
        { title: 'Means and Methods' }
      ],
      images: [
        {
          uuid: 'e0f67cbd-c37c-41da-be16-c4a6a065c054',
          alt: 'Project Estimating'
        },
        {
          uuid: 'd7241549-ef22-4a1b-adff-6206a73694f7',
          alt: 'Blueprints'
        },
        {
          uuid: 'a82ca37c-27e3-4a08-b655-553a76ccf6dd',
          alt: 'Preconstruction Panel Layout Fitment'
        },
        {
          uuid: '5786a0b6-1aab-40af-96d0-834d8c5bfcd4',
          alt: 'Project Estimate'
        },
        {
          uuid: '236ec4d6-ab73-4a2c-9089-9369a76046b4',
          alt: 'Total Station'
        }
      ],
      showPreconDialogForm: false
    }
  }
}
</script>
